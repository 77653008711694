import { graphql, Link, useStaticQuery } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import * as React from "react";
import Layout from "../common/Layout";

const AuthorDetails = ({ data }) => {
  console.log("data", data);
  const { body } = data.authorProfile;
  const authorProfile = data.authorProfile.frontmatter;

  return (
    <Layout>
      <div className="author-detail-page">
        <p className="sub-text back-link mb-1">About</p>
        <h2 className="mb-4">{authorProfile?.name}</h2>
        <div className="row mt-4">
          <div className="col-md-3">
            <div className="author-pic text-center">
              <img
                src={authorProfile?.icon?.publicURL}
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
          <div className="col-md-9">
            <div className="mb-3">
              <MDXRenderer>{body}</MDXRenderer>
            </div>
            {authorProfile?.linkedin && (
              <div className="author-social-links">
                <p className="mb-1">
                  <strong>Follow {authorProfile?.name}</strong>
                </p>
                {authorProfile?.linkedin && (
                  <a href={authorProfile?.linkedin}>
                    <svg
                      width={28}
                      height={28}
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0)">
                        <path
                          d="M27.9951 27.9999V27.999H28.0001V20.664C28.0001 17.0757 27.2276 14.3115 23.0326 14.3115C21.0159 14.3115 19.6626 15.4182 19.1101 16.4674H19.0517V14.6465H15.0742V27.999H19.2159V21.3874C19.2159 19.6465 19.5459 17.9632 21.7017 17.9632C23.8259 17.9632 23.8576 19.9499 23.8576 21.499V27.9999H27.9951Z"
                          fill="#000A30"
                        />
                        <path
                          d="M8.33008 14.6475H12.4767V28H8.33008V14.6475Z"
                          fill="#000A30"
                        />
                        <path
                          d="M10.4017 8C9.07583 8 8 9.07583 8 10.4017C8 11.7275 9.07583 12.8258 10.4017 12.8258C11.7275 12.8258 12.8033 11.7275 12.8033 10.4017C12.8025 9.07583 11.7267 8 10.4017 8V8Z"
                          fill="#000A30"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width={20}
                            height={20}
                            fill="white"
                            transform="translate(8 8)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};
export default AuthorDetails;

export const query = graphql`
  query authorDetails($slug: String) {
    authorProfile: mdx(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        name
        icon {
          publicURL
        }
        slug
        summary
        linkedin
      }
      body
    }
  }
`;
